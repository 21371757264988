export const SoftSkills: string[] = [
    "Software Engineering",
    "Computer Vision",
    "Machine learning",
    "Agile",
    "Test-Driven Development",
    "User-Centred Design",
  ];
  
  export const TechSkills: string[] = [
    "DBMS (MySQL, Oracle)",
    "Java",
    "C/C++",
    "Python",
    "Javascript/Typescript",
    "R",
    "PyTorch",
    "Tensorflow",
    "OpenGL",
    "Containerization (Docker)",
    "Image Registration (ITK)",
    "CUDA",
  ];
  
  export const OfficeSkills: string[] = [
    "Adobe Creative Cloud",
    "Photo and Video Editing",
  ];
  