import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { minScroll, useScroll } from "~/utilities/useScroll";
import { Project, ProjectBody, ProjectParagraph } from "~/portfolio/types";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectPages } from "~/portfolio/projectbodies";
import { Projects } from "~/portfolio/projects";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faXmark } from "@fortawesome/free-solid-svg-icons";

export function ProjectPage() {
  const { stuck, scrollPosition } = useScroll(minScroll);
  const params = useParams();
  const navigate = useNavigate();

  const [navExpanded, setNavExpanded] = useState(false);
  const [projIntro, setProjIntro] = useState<Project>();
  const [project, setProject] = useState<ProjectBody>();
  const [index, setIndex] = useState(-1);

  const [activeSection, setActiveSection] = useState("intro");

  const titleRef = useRef<HTMLHeadingElement>(null),
    introRef = useRef<HTMLHeadingElement>(null),
    objRef = useRef<HTMLHeadingElement>(null),
    procRef = useRef<HTMLHeadingElement>(null),
    execRef = useRef<HTMLHeadingElement>(null),
    outRef = useRef<HTMLHeadingElement>(null),
    futureRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (introRef && introRef.current) {
      const rect = introRef.current.getBoundingClientRect();
      if (rect.top < 150) {
        setActiveSection("intro");
      }
    }
    if (objRef && objRef.current) {
      const rect = objRef.current.getBoundingClientRect();
      if (rect.top < 150) {
        setActiveSection("obj");
      }
    }
    if (procRef && procRef.current) {
      const rect = procRef.current.getBoundingClientRect();
      if (rect.top < 150) {
        setActiveSection("proc");
      }
    }
    if (execRef && execRef.current) {
      const rect = execRef.current.getBoundingClientRect();
      if (rect.top < 150) {
        setActiveSection("exec");
      }
    }
    if (outRef && outRef.current) {
      const rect = outRef.current.getBoundingClientRect();
      if (rect.top < 150) {
        setActiveSection("out");
      }
    }
    if (futureRef && futureRef.current) {
      const rect = futureRef.current.getBoundingClientRect();
      if (rect.top < 150) {
        setActiveSection("future");
      }
    }
  }, [scrollPosition]);

  useEffect(() => {
    navigateToRef(titleRef);
    const _idx = parseInt(params.index as string);
    setIndex(_idx);
    setProjIntro(Projects[_idx]);
    setProject(ProjectPages[Projects[_idx].id]);
  }, [params.index]);

  const scrollOffset = "9rem";

  const mapProjectSection = (section: ProjectParagraph) => {
    if (section.body) {
      if (section.imageURL) {
        if (section.imageFirst) {
          return (
            <div className="row mb-3">
              <div className="col-xxl-6">
                <img className="mb-3 img-fluid" src={section.imageURL} alt="" />
              </div>
              <div className="col-xxl-6">
                <p>{section.body}</p>
              </div>
            </div>
          );
        } else {
          return (
            <div className="row mb-3">
              <div className="col-xxl-6">
                <p>{section.body}</p>
              </div>
              <div className="col-xxl-6">
                <img className="mb-3 img-fluid" src={section.imageURL} alt="" />
              </div>
            </div>
          );
        }
      } else {
        return (
          <div className="row mb-3">
            <p>{section.body}</p>
          </div>
        );
      }
    } else if (section.imageURL) {
      return (
        <div className="row mb-3">
          <img className="mb-3 img-fluid" src={section.imageURL} alt="" />
        </div>
      );
    }
  };

  const navigateToRef = (ref: React.RefObject<HTMLHeadingElement>) => {
    if (ref.current) {
      ref.current.style.scrollMargin = scrollOffset;
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (!project) {
    return (
      <div className={`container-page ${stuck ? "stuck" : ""}`}>
        <div className="scroll-anchor" id="top-of-page"></div>
        <div className={`project pb-5 full-height`}>
          <h2 className={`title`} ref={titleRef}>
            404: Project not found :(
          </h2>
        </div>
        <div>
          <button
            className={`nav-button ${navExpanded ? "expanded" : ""}`}
            onClick={() => setNavExpanded(navExpanded => !navExpanded)}
          >
            {navExpanded ? (
              <FontAwesomeIcon icon={faXmark} />
            ) : (
              <FontAwesomeIcon icon={faList} />
            )}
          </button>
        </div>
        <div className={`info-nav-panel ${navExpanded ? "expanded" : ""}`}>
          <h6>More Projects</h6>
          <hr />
          <div className="more-projects">
            {Projects.map((proj, _index) => {
              if (Object.keys(ProjectPages).includes(proj.id)) {
                return (
                  <p>
                    <a
                      href={`inho.ca/#/project/index`}
                      onClick={e => {
                        e.preventDefault();
                        navigate(`/project/${_index}`);
                      }}
                    >
                      {proj.title}
                    </a>
                  </p>
                );
              } else {
                return undefined;
              }
            })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`container-page ${stuck ? "stuck" : ""}`}>
      <div className="scroll-anchor" id="top-of-page"></div>
      <div className={`project pb-5`}>
        <h1 className={`title`} ref={titleRef}>
          {project.title}
        </h1>
        <div className="section-nav">
          <a
            href="/"
            className={activeSection === "intro" ? "active" : ""}
            onClick={e => {
              e.preventDefault();
              navigateToRef(introRef);
            }}
          >
            Introduction
          </a>
          <a
            href="/"
            className={activeSection === "obj" ? "active" : ""}
            onClick={e => {
              e.preventDefault();
              navigateToRef(objRef);
            }}
          >
            Objective
          </a>
          <a
            href="/"
            className={activeSection === "proc" ? "active" : ""}
            onClick={e => {
              e.preventDefault();
              navigateToRef(procRef);
            }}
          >
            Process
          </a>
          <a
            href="/"
            className={activeSection === "exec" ? "active" : ""}
            onClick={e => {
              e.preventDefault();
              navigateToRef(execRef);
            }}
          >
            Execution
          </a>
          <a
            href="/"
            className={activeSection === "out" ? "active" : ""}
            onClick={e => {
              e.preventDefault();
              navigateToRef(outRef);
            }}
          >
            Outcome
          </a>
          {project.future ? (
            <a
              href="/"
              className={activeSection === "future" ? "active" : ""}
              onClick={e => {
                e.preventDefault();
                navigateToRef(outRef);
              }}
            >
              Future
            </a>
          ) : undefined}
        </div>
        <h2 className="space-intro" ref={introRef}>
          Introduction
        </h2>
        {project.introduction.map(mapProjectSection)}
        <h2 ref={objRef}>Objective</h2>
        {project.objective.map(mapProjectSection)}
        <h2 ref={procRef}>What we did</h2>
        {project.process.map(mapProjectSection)}
        <h2 ref={execRef}>How it went</h2>
        {project.execution.map(mapProjectSection)}
        <h2 ref={outRef}>Outcome</h2>
        {project.outcome.map(mapProjectSection)}
        {project.future ? (
          <div>
            <h2 ref={futureRef}>What's in store for </h2>
            {project.future.map(mapProjectSection)}
          </div>
        ) : undefined}
      </div>
      <div>
        <button
          className={`nav-button ${navExpanded ? "expanded" : ""}`}
          onClick={() => setNavExpanded(navExpanded => !navExpanded)}
        >
          {navExpanded ? (
            <FontAwesomeIcon icon={faXmark} />
          ) : (
            <FontAwesomeIcon icon={faList} />
          )}
        </button>
      </div>
      <div className={`info-nav-panel ${navExpanded ? "expanded" : ""}`}>
        <h6>More Projects</h6>
        <hr />
        <div className="more-projects">
          {Projects.map((proj, _index) => (
            <p>
              <a
                href={proj.url ? proj.url : "/"}
                target="_blank"
                rel="noreferrer"
                className={_index === index ? "active" : ""}
                onClick={e => {
                  (!proj.url || proj.localUrl) && e.preventDefault();
                  proj.localUrl && proj.url && navigate(`/project/${_index}`);
                }}
              >
                {proj.title}
              </a>
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}
